import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import COMPANIES from 'static/constants/companies';
import INSPECTORS from 'static/constants/inspectors';
import getTags from 'static/constants/tags';
import { Option } from 'types/option';
import styles from './override-mui-styles';
import './styles.scss';
import { BrandsId, CountryCode, ResourceAction, Rol } from 'types/common';
import Switch from '@mui/material/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { userActionsPermissions } from 'shared/utils';
import useDevice from 'shared/hooks/useDevice';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getAgentsFilter } from 'context/inspections';
import { FilterState } from 'types/filter-state';
import { companyFilterPermission } from 'shared/utils/company-filter-permission';
import { IUserModel } from 'types/user';

interface Props {
  showOnlyDatePicker?: boolean;
  company: Option | null;
  inspector: Option | null;
  agent: Option | null;
  tag: Option | null;
  expressAdjust?: boolean | null;
  aapIndicator?: boolean | null;
  startDate: Date | null;
  endDate: Date | null;
  showMyServices?: boolean;
  stageSelected?: FilterStages;
  state?: Option | null;
  currentUser: IUserModel;
  onChangeCompany?: (option: Option | null) => void;
  onChangeInspector?: (option: Option | null) => void;
  onChangeTag?: (option: Option | null) => void;
  onChangeExpressAdjust?: (value: boolean | null) => void;
  onChangeAapIndicator?: (value: boolean | null) => void;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  onChangeDateRange: (dates: Date[]) => void;
  setCurrentInspector?: (option: Option[]) => void;
  setCurrentCompany?: (option: Option[]) => void;
  setCurrentAgent?: (option: Option[]) => void;
  setCurrentState?: (option: Option<FilterState, string>[]) => void;
  setCurrentTag?: (option: Option[]) => void;
  onServicesCheckChange?: (myServices: boolean) => void;
  onChangeStage?: (stageId: FilterStages | undefined) => void;
  onChangeAgent?: (option: Option | null) => void;
  onChangeState?: (option: Option | null) => void;
}

export enum FilterStages {
  FILTER_CORDINATION_INSPECTION = 1,
  FILTER_INSPECTION = 2,
  FILTER_AUDIT = 3,
  FILTER_DAMAGE_ESTIMATED = 4,
  FILTER_ADJUST_ESTIMATED = 5,
  FILLTER_FINAL_AGREEMENT = 6,
  FILTER_CANCELED_CLAIM = 8,
  FILTER_DELETED_CLAIM = 9,
  FILTER_FINISHED_CLAIM = 10,
  FILTER_TOTAL_LOSS = 12,
  FILTER_SUBROGATION = 13,
  FILTER_PENDING_INSURANCE_PROCESSED = 14,
  FILTER_FINISHED_INSURANCE_PROCESSED = 16,
  FILTER_PENDING_SINISTER = 17,
  FILTER_FINALIZED_SINITER = 18,
  FILTER_MEDICAL_AUDIT = 19,
  FILTER_DAMAGE_ANALISYS = 20,
  FILTER_LEGAL_CONCEPT = 21,
}

const GridFilters = (props: Props): JSX.Element => {
  const classes = styles();
  const [listCompanies, setListCompanies] = useState<any[]>([]);
  const [listInspectors, setListInspectors] = useState<any[]>([]);
  const [listAgents, setListAgents] = useState<any[]>([]);

  const userRolId = localStorage.getItem('rolId');

  interface IfilterStage {
    filterStage: FilterStages;
    text: string;
    order: number;
    visible: boolean;
  }

  type typeFilterCountry = {
    [key in CountryCode]: IfilterStage[];
  };

  const FiltersCountry: typeFilterCountry = {
    [CountryCode.PRI]: [
      {
        filterStage: FilterStages.FILTER_INSPECTION,
        text: 'Inspección',
        order: 1,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_AUDIT,
        text: 'Auditoría',
        order: 2,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_DAMAGE_ESTIMATED,
        text: 'Estimado de daños',
        order: 3,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_ADJUST_ESTIMATED,
        text: 'Ajuste de daños',
        order: 4,
        visible: true,
      },
      {
        filterStage: FilterStages.FILLTER_FINAL_AGREEMENT,
        text: 'Acuerdo Final',
        order: 5,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_FINISHED_CLAIM,
        text: 'Caso Finalizado',
        order: 6,
        visible: true,
      },
    ],
    [CountryCode.PAN]: [
      {
        filterStage: FilterStages.FILTER_INSPECTION,
        text: 'Inspección',
        order: 1,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_AUDIT,
        text: 'Auditoría',
        order: 2,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_PENDING_INSURANCE_PROCESSED,
        text: 'Pendiente Aseguradora',
        order: 3,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_FINISHED_INSURANCE_PROCESSED,
        text: 'Procesado Aseguradora',
        order: 4,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_DAMAGE_ESTIMATED,
        text: 'Estimado de daños',
        order: 5,
        visible: false,
      },
      {
        filterStage: FilterStages.FILTER_ADJUST_ESTIMATED,
        text: 'Ajuste de daños',
        order: 6,
        visible: false,
      },
      {
        filterStage: FilterStages.FILLTER_FINAL_AGREEMENT,
        text: 'Acuerdo Final',
        order: 7,
        visible: false,
      },
      {
        filterStage: FilterStages.FILTER_FINISHED_CLAIM,
        text: 'Caso Finalizado',
        order: 8,
        visible: true,
      },
    ],
    [CountryCode.CRC]: [
      {
        filterStage: FilterStages.FILTER_INSPECTION,
        text: 'Inspección',
        order: 1,
        visible: ![Rol.CUSTOMER].includes(Number(userRolId)),
      },
      {
        filterStage: FilterStages.FILTER_PENDING_SINISTER,
        text: 'Siniestrar',
        order: 2,
        visible:
          ![Rol.CUSTOMER].includes(Number(userRolId)) &&
          [BrandsId.CONNECT_CR, BrandsId.QUALITAS].includes(
            Number(localStorage.getItem('companyId')),
          ),
      },
      {
        filterStage: FilterStages.FILTER_AUDIT,
        text: 'Auditoría',
        order: 2,
        visible: [BrandsId.CONNECT_CR, BrandsId.LAFISE].includes(
          Number(localStorage.getItem('companyId')),
        ),
      },
      {
        filterStage: FilterStages.FILTER_PENDING_INSURANCE_PROCESSED,
        text: 'Pendiente Aseguradora',
        order: 3,
        visible:
          ![Rol.CUSTOMER].includes(Number(userRolId)) &&
          [BrandsId.CONNECT_CR, BrandsId.QUALITAS].includes(
            Number(localStorage.getItem('companyId')),
          ),
      },
      {
        filterStage: FilterStages.FILTER_FINISHED_CLAIM,
        text: 'Caso Finalizado',
        order: 4,
        visible: ![Rol.CUSTOMER].includes(Number(userRolId)),
      },
    ],
    [CountryCode.COL]: [
      {
        filterStage: FilterStages.FILTER_INSPECTION,
        text: 'Inspección',
        order: 1,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_AUDIT,
        text: 'Auditoría',
        order: 2,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_DAMAGE_ANALISYS,
        text: 'Análisis de daños',
        order: 3,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_MEDICAL_AUDIT,
        text: 'Auditoría Médica',
        order: 4,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_LEGAL_CONCEPT,
        text: 'Concepto Jurídico',
        order: 5,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_ADJUST_ESTIMATED,
        text: 'Ajuste de daños',
        order: 6,
        visible: true,
      },
      {
        filterStage: FilterStages.FILLTER_FINAL_AGREEMENT,
        text: 'Acuerdo Final',
        order: 7,
        visible: true,
      },
      {
        filterStage: FilterStages.FILTER_FINISHED_CLAIM,
        text: 'Caso Finalizado',
        order: 8,
        visible: true,
      },
    ],
  };

  const listStatesPACR: Option<FilterState, string>[] = [
    {
      id: FilterState.FILTER_CANCELLED,
      name: 'Cancelado',
    },
    {
      id: FilterState.FILTER_DELETED,
      name: 'Eliminado',
    },
  ];

  const listStatesDefault: Option<FilterState, string>[] = [
    {
      id: FilterState.FILTER_CANCELLED,
      name: 'Cancelado',
    },
    {
      id: FilterState.FILTER_DELETED,
      name: 'Eliminado',
    },
    {
      id: FilterState.FILTER_TOTAL_LOSS,
      name: 'Perdida total',
    },
    {
      id: FilterState.FILTER_SUBROGATION,
      name: 'Subrogación',
    },
    {
      id: FilterState.FILTER_PENDING_APPROVAL,
      name: 'Pendiente de aprobación',
    },
  ];

  const listStatesOptima: Option<FilterState, string>[] = [
    {
      id: FilterState.FILTER_CANCELLED,
      name: 'Cancelado',
    },
    {
      id: FilterState.FILTER_DELETED,
      name: 'Eliminado',
    },
    {
      id: FilterState.FILTER_TOTAL_LOSS,
      name: 'Perdida total',
    },
    {
      id: FilterState.FILTER_SUBROGATION,
      name: 'Subrogación',
    },
    {
      id: FilterState.FILTER_PENDING_APPROVAL,
      name: 'Pendiente de aprobación',
    },
    {
      id: FilterState.FILTER_PENDING_INSURANCE,
      name: 'Pendiente aseguradora',
    },
    {
      id: FilterState.FILTER_PENDING_INFORMATION,
      name: 'Pendiente de Información',
    },
  ];

  const [listStates, setListStates] = useState<Option<FilterState, string>[]>(
    listStatesOptima,
  );

  const [tagList, setTagList] = useState<any[]>([]);
  const [moreFilters, setMoreFilters] = useState<boolean>(
    localStorage.getItem('moreFilters') === 'true',
  );
  const { isDesktop } = useDevice();

  const {
    setCurrentTag,
    setCurrentCompany,
    setCurrentInspector,
    setCurrentAgent,
    setCurrentState,
  } = props;

  const getTagList = useCallback(() => {
    const tagOptions = Array.from(getTags());
    if (setCurrentTag) {
      setCurrentTag(tagOptions);
    }

    return tagOptions;
  }, [setCurrentTag]);

  const getAgents = useCallback(async () => {
    const agents = await getAgentsFilter();
    setListAgents(agents);

    if (setCurrentAgent) {
      setCurrentAgent(agents as Option[]);
    }
  }, [setCurrentAgent]);

  const getStates = useCallback(async () => {
    setListStates(listStates);

    if (setCurrentState) {
      setCurrentState(listStates);
    }
  }, [setCurrentState]);

  const getInspectors = useCallback(() => {
    const formattedInspectors = Array.from(INSPECTORS.getDataInspectors());
    if (setCurrentInspector) {
      setCurrentInspector(formattedInspectors);
    }

    return formattedInspectors;
  }, [setCurrentInspector]);

  const getCompanies = useCallback(() => {
    const filteredCompanies = COMPANIES(true, true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode'),
    );

    if (setCurrentCompany) {
      setCurrentCompany(filteredCompanies);
    }

    return filteredCompanies;
  }, [setCurrentCompany]);

  const getCompaniesByRol = useCallback(() => {
    return COMPANIES(true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode') &&
        f.id === Number(localStorage.getItem('companyId')),
    );
  }, []);

  const myPermissionIsValid = (action: ResourceAction): boolean => {
    return Boolean(userActionsPermissions(action));
  };

  const aapFilterPermission = (): boolean => {
    return (
      localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.CRC &&
      props.aapIndicator !== undefined &&
      userRolId !== Rol.CUSTOMER.toString() &&
      isDesktop
    );
  };

  const handleStageSelected = (stageFilterId: FilterStages): void => {
    if (props.onChangeStage) {
      props.onChangeStage(stageFilterId);
    }
  };

  const handleMoreFilters = (): void => {
    if (moreFilters) {
      setMoreFilters(false);
      localStorage.setItem('moreFilters', 'false');
    } else {
      setMoreFilters(true);
      localStorage.setItem('moreFilters', 'true');
    }
  };

  const expressIndicatorPermission = (): boolean => {
    const userRolId = localStorage.getItem('rolId');

    return (
      userRolId !== Rol.CUSTOMER.toString() &&
      (Number(localStorage.getItem('companyId')) === BrandsId.QUALITAS ||
        Number(localStorage.getItem('companyId')) === BrandsId.SURA ||
        [Rol.ADMIN, Rol.AUDIT, Rol.ADJUSTER].includes(Number(userRolId)))
    );
  };

  useEffect(() => {
    if (listInspectors.length === 0) {
      setListInspectors(getInspectors());
    }

    if (listAgents.length === 0 || props.currentUser.isMultiCountry) {
      getAgents();
    }

    getStates();

    if (listCompanies.length === 0 || props.currentUser.isMultiCountry) {
      const userRolId = localStorage.getItem('rolId');
      if (
        userRolId === Rol.INSURER.toString() ||
        userRolId === Rol.CLAIMS_ASSISTANT.toString() ||
        userRolId === Rol.CUSTOMER.toString()
      ) {
        setListCompanies(getCompaniesByRol());
      } else {
        setListCompanies(getCompanies());
      }
    } else {
      if (
        [CountryCode.CRC, CountryCode.PAN].includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
      ) {
        setListStates(listStatesPACR);
      } else if (listCompanies.find((x) => x.id === BrandsId.OPTIMA_SEGUROS)) {
        setListStates(listStatesOptima);
      } else {
        setListStates(listStatesDefault);
      }
    }

    if (tagList.length === 0) {
      setTagList(getTagList());
    }
  }, [
    listInspectors.length,
    listCompanies.length,
    tagList.length,
    listAgents.length,
    props.currentUser,
  ]);

  return (
    <>
      <div className="filters-claims">
        {myPermissionIsValid(ResourceAction.USE_FILTER_MY_SERVICES) &&
          isDesktop && (
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ width: 'max-content', margin: '0 10px' }}
              className="filters-claims__toggle-events"
            >
              <Grid
                item
                style={{
                  fontSize: '16px',
                  fontFamily: 'Connect',
                  fontWeight: 'bold',
                }}
              >
                Todos
              </Grid>
              <Grid item>
                <Switch
                  checked={props.showMyServices}
                  onChange={(_, checked) => {
                    if (props.onServicesCheckChange) {
                      props.onServicesCheckChange(checked);
                    }
                  }}
                  name="show services"
                />
              </Grid>
              <Grid
                item
                style={{
                  fontSize: '16px',
                  fontFamily: 'Connect',
                  fontWeight: 'bold',
                }}
              >
                Mis Casos
              </Grid>
            </Grid>
          )}

        {expressIndicatorPermission() &&
          (localStorage.getItem('countryCode') as CountryCode) !=
            CountryCode.COL && (
            <Button
              className={`filters-claims__stages ${
                props.expressAdjust ? 'filters-claims__stages--selected' : ''
              }`}
              onClick={() => {
                if (props.onChangeExpressAdjust) {
                  props.onChangeExpressAdjust(!props.expressAdjust);
                }
              }}
            >
              {localStorage.getItem('countryCode')?.toUpperCase() ===
              CountryCode.CRC
                ? 'QualiExpress'
                : Number(localStorage.getItem('companyId')) === BrandsId.SURA
                ? 'Acompañamiento'
                : 'Ajuste Express'}
            </Button>
          )}

        {FiltersCountry[localStorage.getItem('countryCode') as CountryCode]
          ?.sort((a, b) => a.order - b.order)
          ?.map((filter) => {
            return (
              filter.visible && (
                <div key={filter.filterStage}>
                  <Button
                    title={filter.text}
                    className={`filters-claims__stages ${
                      props.stageSelected === filter.filterStage
                        ? 'filters-claims__stages--selected'
                        : ''
                    }`}
                    onClick={() => handleStageSelected(filter.filterStage)}
                  >
                    {filter.text}
                  </Button>
                </div>
              )
            );
          })}

        <Button
          className={`filters-claims__stages ${
            moreFilters ? 'filters-claims__stages--selected' : ''
          }`}
          style={{ marginLeft: 'auto' }}
          onClick={handleMoreFilters}
        >
          Más Filtros
          <FilterAltIcon />
        </Button>
      </div>
      {moreFilters && (
        <div className="filters-claims">
          <span className="filters-claims__label-filter">Filtros:</span>

          {myPermissionIsValid(ResourceAction.VIEW_TAGS) && isDesktop && (
            <Autocomplete
              options={tagList}
              getOptionLabel={(option: Option) => option.name}
              sx={{ width: 300 }}
              onChange={(ev, option: Option | null) => {
                if (props.onChangeTag) {
                  props.onChangeTag(option);
                }
              }}
              value={props.tag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Tag"
                  classes={{ root: classes.inputRoot }}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                />
              )}
            />
          )}

          <DatePicker
            startDate={props.startDate}
            endDate={props.endDate}
            onChangeStartDate={props.onChangeStartDate}
            onChangeEndDate={props.onChangeEndDate}
            onChangeDateRange={props.onChangeDateRange}
          />

          {!props.showOnlyDatePicker && (
            <>
              {companyFilterPermission(
                userRolId,
                props.currentUser.isMultiCountry,
                myPermissionIsValid,
              ) && (
                <Autocomplete
                  options={listCompanies}
                  getOptionLabel={(option: Option) => option.name}
                  sx={{ width: 300 }}
                  onChange={(ev, option: Option | null) => {
                    if (props.onChangeCompany) {
                      props.onChangeCompany(option);
                    }
                  }}
                  value={props.company}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Compañía"
                      classes={{ root: classes.inputRoot }}
                      sx={{
                        '& fieldset': { border: 'none' },
                      }}
                    />
                  )}
                />
              )}
              {myPermissionIsValid(ResourceAction.USE_FILTER_INSPECTORS) &&
                isDesktop && (
                  <Autocomplete
                    options={listInspectors}
                    getOptionLabel={(option: Option) => option.name}
                    sx={{ width: 300 }}
                    onChange={(ev, option: Option | null) => {
                      if (props.onChangeInspector) {
                        props.onChangeInspector(option);
                      }
                    }}
                    value={props.inspector}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Inspector"
                        classes={{ root: classes.inputRoot }}
                        sx={{
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                )}

              {myPermissionIsValid(ResourceAction.USE_FILTER_AGENTS) &&
                isDesktop && (
                  <Autocomplete
                    options={listAgents}
                    getOptionLabel={(option: Option) => option.name}
                    sx={{ width: 300 }}
                    onChange={(ev, option: Option | null) => {
                      if (props.onChangeAgent) {
                        props.onChangeAgent(option);
                      }
                    }}
                    value={props.agent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Agente"
                        classes={{ root: classes.inputRoot }}
                        sx={{
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                )}

              {myPermissionIsValid(ResourceAction.USE_FILTER_OTHER_STATUS) &&
                isDesktop && (
                  <Autocomplete
                    options={listStates}
                    getOptionLabel={(option: Option) => option.name}
                    sx={{ width: 300 }}
                    onChange={(ev, option: Option | null) => {
                      if (props.onChangeState) {
                        props.onChangeState(option);
                      }
                    }}
                    value={props.state}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Otros Estados"
                        classes={{ root: classes.inputRoot }}
                        sx={{
                          '& fieldset': { border: 'none' },
                        }}
                      />
                    )}
                  />
                )}

              {aapFilterPermission() && (
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={Boolean(props.aapIndicator)}
                      onChange={() => {
                        if (props.onChangeAapIndicator) {
                          props.onChangeAapIndicator(!props.aapIndicator);
                        }
                      }}
                      color="primary"
                      name="AapFilter"
                    />
                  }
                  label="AAP"
                  labelPlacement="start"
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default GridFilters;
