import React, {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import { isEqual, set } from 'lodash';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import * as API from 'services/claims';
import {
  IInspectionContext,
  InspectionContext,
  downloadEvent,
  finishEvent,
  getClaimById,
  getReasonsPendingInfo,
  integrationLafise,
  sendActiveLink,
  sendClientPdf,
} from 'context/inspections';
import { FormDataFilter } from 'shared/utils/filter-data';
import {
  FieldTypes,
  IClaimForm,
  StepStatus,
  CurrentStatusText,
  IClaim,
  ClaimantType,
  StageIds,
  StatusIds,
} from 'types/claimsTracker';
import {
  BrandsId,
  CountryCode,
  AccountsEnableEditInfo,
  ReasonPendingInfo,
  Rol,
  TransitionStatesIds,
} from 'types/common';
import Loader from 'components/common/loader/Loader';
import Photos from '../customTargets/photos';
import ClaimEstimated from '../estimated';
import Depreciation from '../depreciation';
import FormFieldsComponent from '../form/FormFieldsComponent';
import Button from '../button';
import ClaimExpenses from '../expense';
import AgreementDetail from '../agreementDetail';
import DialogComponent from 'components/common/dialog';
import './index.scss';
import { ActionType } from 'types/action';
import ClaimRequest from 'views/ClaimsTracker/claimRequest';
import TotalLoss from '../totalLoss';
import Subrogation from '../subrogation';
import FinalResolution from '../subrogationFinalResolution';
import EventTracking from '../subrogationEventTracking';
import { keyValueStructure, transformFormValueByType } from 'shared/utils';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import ThirdsForm from '../thirds/Thirds';
import CATALOGS from 'static/constants/catalogs';
import ClaimAutoParts from '../autoParts';
import { sinisterClaims } from 'context/inspections/actions/actions';
import CircunstancesFormClaims from '../circunstances/CircunstancesFormClaims';
import { SelectOption } from 'types/select-option-inspection';
import roles from 'static/constants/roles';
import { tr } from 'date-fns/locale';

interface ClaimsSections {
  claimUId: string;
  sections: any;
  legacyCurrentClaim?: IClaim;
  setLegacyCurrentClaim?: (data: IClaim) => void;
  currentClaimForm?: IClaimForm;
  steps: SelectOption[];
}

interface OptionStep {
  IsFinishedWithoutEstimated: boolean;
  IsClaimNotApplicable: boolean;
  RequireInformation: boolean;
  Objeted: boolean;
  ChangeStatusRevert: boolean;
}

interface NewStatus {
  StageId: number;
  StatusId: number;
}

interface Status {
  newStatus: NewStatus;
  text: string;
}

type typeStatus = {
  [key in CountryCode]: Status[];
};

const listStatus: typeStatus = {
  [CountryCode.COL]: [
    {
      newStatus: {
        StageId: StageIds.DAMAGE_ANALYSIS,
        StatusId: StatusIds.PENDING_MEDICAL_AUDIT,
      },
      text: 'Auditoría Médica',
    },
    {
      newStatus: {
        StageId: StageIds.DAMAGE_ANALYSIS,
        StatusId: StatusIds.PENDING_LEGAL_CONCEPT,
      },
      text: 'Concepto Jurídico',
    },
    {
      newStatus: {
        StageId: StageIds.DAMAGE_ANALYSIS,
        StatusId: StatusIds.PENDING_ADJUSTMENT,
      },
      text: 'Estimado/Ajuste',
    },
  ],
  [CountryCode.PRI]: [
    {
      newStatus: {
        StageId: StageIds.INSPECT_SECTION,
        StatusId: StatusIds.PENDING_AUDIT,
      },
      text: 'Auditoría',
    },
    {
      newStatus: {
        StageId: StageIds.DAMAGE_ANALYSIS,
        StatusId: StatusIds.PENDING_ESTIMATE,
      },
      text: 'Estimado',
    },
    {
      newStatus: {
        StageId: StageIds.DAMAGE_ANALYSIS,
        StatusId: StatusIds.PENDING_ADJUSTMENT,
      },
      text: 'Ajuste',
    },
  ],
  [CountryCode.PAN]: [],
  [CountryCode.CRC]: [],
};

const ClaimSections: FunctionComponent<ClaimsSections> = ({
  claimUId,
  sections,
  legacyCurrentClaim,
  setLegacyCurrentClaim,
  currentClaimForm,
  steps,
}): JSX.Element => {
  const history = useHistory();
  const formikRef = useRef<any>(null);
  const { state, dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [initialValues, setInitialValues] = useState<any>({});
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<any>(null);
  const [showConfirmSubmit, setShowConfirmSubmit] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogMsg, setOpenDialogMsg] = useState('');
  const [isDetectedChanges, setIsDetectedChanges] = useState<boolean>(false);
  const [isDetectedError, setIsDetectedError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<any>(null);
  const [saveInProcess, setSaveInProcess] = useState<Date>(new Date());
  const [isFinishDisableByCondition, setIsFinishDisableByCondition] = useState<
    boolean
  >(false);
  const [isFinishedWithoutEstimated, setIsFinishedWithoutEstimated] = useState<
    boolean
  >(false);
  const [optionsStep, setOptionStep] = useState<OptionStep>({
    IsClaimNotApplicable: false,
    IsFinishedWithoutEstimated: false,
    RequireInformation: false,
    Objeted: false,
    ChangeStatusRevert: false,
  });

  const [reasonStatusChange, setReasonStatusChange] = useState<string>('');
  const [reasonSelect, setReasonSelect] = useState<string>();
  const [textNotification, setTextNotification] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<NewStatus | undefined>();
  const [isDisabledButtonConfirm, setIsDisabledButtonConfirm] = useState<
    boolean
  >(false);
  const [confirmActiveLink, setConfirmActiveLink] = useState<boolean>(false);
  const [finishBtnIsDisable, setFinishBtnIsDisable] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const customStyle = {
    padding: '8px 16px 8px 16px',
    height: 'auto',
    gap: '8px',
  };
  const userRole = Number(localStorage.getItem('rolId'));
  const [reasonPendingInfo, setReasonPendingInfo] = React.useState<
    ReasonPendingInfo[]
  >([]);

  const isDisabled =
    (Number(localStorage.getItem('rolId')) === Rol.INSURER &&
      !AccountsEnableEditInfo.includes(
        legacyCurrentClaim?.jsonFieldData?.event.CompanyId as BrandsId,
      )) ||
    Number(localStorage.getItem('rolId')) === Rol.CUSTOMER ||
    Number(localStorage.getItem('rolId')) === Rol.CLAIMS_ASSISTANT;

  const isDisabledButton =
    (Number(localStorage.getItem('rolId')) === Rol.INSURER &&
      currentClaimForm?.info.currentStepStatus !==
        StepStatus.PENDING_INSURANCE_PROCESSED &&
      !AccountsEnableEditInfo.includes(
        legacyCurrentClaim?.jsonFieldData?.event.CompanyId as BrandsId,
      )) ||
    (Number(localStorage.getItem('rolId')) === Rol.INSPECTOR &&
      currentClaimForm?.info.currentStepStatus !==
        StepStatus.PENDING_INSPECT) ||
    (legacyCurrentClaim?.jsonFieldData?.event.CompanyId === BrandsId.CSM &&
      legacyCurrentClaim?.jsonFieldData.event.IndEstimate === '2' &&
      currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_AUDIT);

  const pendingApproval =
    currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_APPROVAL;

  const [
    showFinishWithoutEstimatedButton,
    setShowFinishWithoutEstimatedButton,
  ] = useState(
    currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_AUDIT &&
      Number(legacyCurrentClaim?.accountReference) === BrandsId.CSM &&
      (userRole === Rol.ADJUSTER ||
        userRole === Rol.ADMIN ||
        userRole === Rol.AUDIT),
  );

  const showButtonNotApplicable =
    currentClaimForm?.info.currentStepStatus ===
      StepStatus.PENDING_DAMAGE_ANALYSIS &&
    currentClaimForm?.info.countryCode === CountryCode.COL;

  const showButtonPendingInfo =
    currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_AUDIT &&
    (currentClaimForm?.info.countryCode === CountryCode.COL ||
      currentClaimForm?.info.countryCode === CountryCode.PAN ||
      currentClaimForm?.info.countryCode === CountryCode.PRI);

  const showButtonChangeStatus =
    Number(StatusIds[currentClaimForm?.info.currentStepStatus]) >
      StatusIds.PENDING_AUDIT &&
    currentClaimForm?.info.countryCode === CountryCode.PRI &&
    userRole === Rol.ADMIN;

  const showButtonObjeted =
    currentClaimForm?.info.currentStepStatus ===
      StepStatus.PENDING_APPRO_AGREE &&
    currentClaimForm?.info.countryCode === CountryCode.COL;
  const showFinishWithoutSinister =
    currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_SINISTER &&
    (userRole === Rol.INSURER || userRole === Rol.ADMIN);

  const enabledByStates = [StepStatus.IN_PROCESS, StepStatus.PENDING].includes(
    sections.status,
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const initOptionStep = () => {
    setOptionStep({
      IsFinishedWithoutEstimated: false,
      IsClaimNotApplicable: false,
      RequireInformation: false,
      Objeted: false,
      ChangeStatusRevert: false,
    });
  };

  const handleClickOptionStep = (event: any) => {
    loadReasonsPendingInfo(currentClaimForm?.info.countryId);
    initOptionStep();
    setIsDisabledButtonConfirm(false);
    setShowConfirmSubmit(true);
    optionsStep[event.target?.id] = true;

    if (!optionsStep.IsClaimNotApplicable && !optionsStep.Objeted) {
      setReasonStatusChange('');
      setStatusSelected(undefined);
    }

    if (!optionsStep.RequireInformation) {
      setTextNotification('');
    }

    setOptionStep(optionsStep);
  };

  const handleChangeStatus = (event: any) => {
    const [stageId, statusId] = String(event.target?.value).split('-');
    setStatusSelected({ StageId: Number(stageId), StatusId: Number(statusId) });
  };

  const handleChange = (panel: number) => (
    event: React.SyntheticEvent,
    newExpanded: boolean,
  ) => {
    setExpanded({ ...expanded, [panel]: newExpanded });
    if (expanded) {
      window.setTimeout(() => executeScroll(panel), 500);
    }
  };

  const executeScroll = (panel: number) => {
    const elementAccordion = document.getElementById(`panel${panel}-header`);
    elementAccordion?.scrollIntoView();
  };

  const formatDate = (date: string): string => {
    const formattedDate = dayjs(date)
      .locale('es')
      .format(CATALOGS.getCountryFormat().dateTimeFormat + ' HH:mm:ss')
      .toUpperCase();

    return formattedDate === 'INVALID DATE' ? '' : formattedDate;
  };

  const validations = (data: any) => {
    let schema = Yup.string();

    if (data.isRequired && data.isVisible && !data.isReadOnly) {
      schema = schema.required('Campo requerido');
    }

    if (data.min) {
      schema = schema.min(data.min, `Mínimo ${data.min} caracteres`);
    }

    if (data.max) {
      schema = schema.max(data.max, `Máximo ${data.max} caracteres`);
    }

    if (data.regex) {
      schema = schema.matches(new RegExp(data.regex), 'Formato inválido');
    }

    return schema;
  };

  async function loadReasonsPendingInfo(CountryId: number) {
    const reasonsPendingInfoToLoad = await getReasonsPendingInfo(CountryId);
    if (reasonsPendingInfoToLoad) {
      setReasonPendingInfo(reasonsPendingInfoToLoad);
    }
  }

  const getValidationSchema = (sections: any) => {
    return Yup.object().shape(
      (sections.sections as any[])
        .flatMap((section) =>
          section.containers.flatMap((container) => container.fields),
        )
        .reduce((schema, field) => {
          let replace = field.modelBinding;
          if (field.modelBinding && field.modelBinding.includes('.')) {
            replace = field.modelBinding.replace('.', '&');
          }
          const fieldName = `${replace}`;
          schema[fieldName] = validations(field.constraints);

          return schema;
        }, {}),
    );
  };

  useEffect(() => {
    setIsFinishDisableByCondition(false);
  }, [currentClaimForm?.info?.case]);

  useEffect(() => {
    const updateSessionStorage = () => {
      const currentValues = formikRef?.current?.values || {};
      const storedValues = JSON.parse(
        sessionStorage.getItem('formikValues') || '{}',
      );
      const updatedValues = { ...storedValues, ...currentValues };
      sessionStorage.setItem('formikValues', JSON.stringify(updatedValues));
    };

    const intervalId = setInterval(() => {
      if (formikRef?.current) {
        updateSessionStorage();
      }
    }, 400);

    return () => clearInterval(intervalId);
  }, [formikRef]);

  useEffect(() => {
    const storedValues = JSON.parse(
      sessionStorage.getItem('formikValues') || '{}',
    );

    const computedValues = sections.sections
      .map((item) => ({ ...item }))
      .map((item) => ({ ...item }))
      .map((item) => [...item.containers])
      .reduce((values, containers) => {
        containers.forEach((container) => {
          container.fields.forEach((field) => {
            let replace = field.modelBinding;
            if (field.modelBinding && field.modelBinding.includes('.')) {
              replace = field.modelBinding.replace('.', '&');
            }
            const fieldName = replace;
            values[fieldName] =
              storedValues[fieldName] !== undefined
                ? storedValues[fieldName]
                : field.defaultValue || '';
          });
        });

        return values;
      }, {});

    setInitialValues(computedValues);
    setValidationSchema(getValidationSchema(sections));

    const currentDetailId = sessionStorage.getItem('currentDetailId') ?? '';
    if (currentDetailId.length > 0 && currentDetailId !== claimUId) {
      sessionStorage.setItem('currentStep', '');
    } else {
      sessionStorage.setItem('currentStep', sections.stageName);
    }

    sessionStorage.setItem('currentDetailId', claimUId);

    if (formikRef?.current) {
      formikRef.current.setValues(computedValues);
    }
  }, [sections, formikRef, setInitialValues, setValidationSchema, claimUId]);

  const triggerSave = useCallback(
    (
      data: any,
      requestNextStep: boolean | undefined,
      modelOptions?: OptionStep,
      reasonStatus?: string,
      textNotification?: string,
      newStatus?: NewStatus,
      sinister: boolean | undefined = true,
    ) => {
      const keyValue =
        data && Object.keys(data).length > 0
          ? keyValueStructure(data)[0].split('::')
          : undefined;
      (async (): Promise<void> => {
        try {
          let hasErrorInSave = false;

          //Validate dataType
          if (keyValue) {
            const currentStep = sessionStorage.getItem('currentStep') ?? '';
            let currentSection = sections;
            if (currentStep !== sections?.stageName) {
              currentSection =
                currentClaimForm?.steps.find(
                  (s: any) => s.stageName === currentStep,
                ) ?? sections;
            }

            data[keyValue[0]] = transformFormValueByType(
              currentSection,
              keyValue[1],
              Object.keys(data)[0],
            );
          }

          // If the form has data to send
          if (Object.keys(data).length > 0) {
            FormDataFilter(data);
            const resultSave = await API.saveClaim(data, currentClaimForm);

            if (
              resultSave &&
              resultSave.data &&
              resultSave.data.eventRecord === currentClaimForm?.info.poNumber
            ) {
              hasErrorInSave = false;
              if (
                keyValue &&
                formikRef.current.values[keyValue[0]] !== keyValue[1]
              ) {
                formikRef.current.values[keyValue[0]] = keyValue[1];
              }
              setInitialValues(formikRef.current.values);

              if (
                currentClaimForm?.info?.countryCode === 'PRI' &&
                data.IndEstimate != null
              ) {
                window.location.reload();
              }
            } else {
              hasErrorInSave = true;
              setLoading(false);
              // eslint-disable-next-line no-console
              console.log('Error al procesar el guardado.');
              setIsDetectedError(true);
              setMsgError(JSON.stringify(resultSave));
            }
          }

          if (requestNextStep && !hasErrorInSave) {
            triggerStatus(
              {
                CurrentStage: currentClaimForm?.info.currentStep,
                CurrentStatus: currentClaimForm?.info.currentStepStatus,
                UserId: Number(localStorage.getItem('userId')),
                RequiredApproval: legacyCurrentClaim?.requiresApproval,
                ReasonStatusChange: reasonStatus,
                TextNotification: textNotification,
                NewStatus: newStatus,
                ...modelOptions,
              },
              sinister,
            );
          } else {
            dispatch({ type: ActionType.SET_LOADING, payload: false });
          }
        } catch (error) {
          setLoading(false);
          console.error('Error saving claim:', error);
          setIsDetectedError(true);
          setMsgError(JSON.stringify(error));
        } finally {
          setIsDisabledButtonConfirm(false);
        }
      })();
    },
    [],
  );

  const triggerStatus = useCallback((data, sinister: boolean | undefined) => {
    (async (): Promise<void> => {
      try {
        localStorage.setItem('currentDetailId', 'refresh');
        sessionStorage.setItem('currentStep', '');

        console.warn('ACTION STEP', currentClaimForm?.info.currentStepStatus);

        const resultAction = await actionStep(
          currentClaimForm?.info.currentStepStatus,
          sinister,
        );

        console.warn('RESULT_ACTION', resultAction);

        if (resultAction) {
          dispatch({ type: ActionType.SET_LOADING, payload: true });
          await API.putClaimStatus(data, claimUId);
          history.go(0);
        }
      } catch (error) {
        console.error('Error updating claim status:', error);
      }
    })();
  }, []);

  const onChangeForm = (e: any) => {
    if (formikRef.current) {
      const { name, className, innerText } = e.target;

      let isDatePickerElement = false;
      let isSelectedElement = false;
      let isTimePickerElement = false;
      let isDamageSelection = false;

      if (className) {
        isDatePickerElement = className.includes('MuiPickersDay-root');
        isSelectedElement = className.includes('MuiMenuItem-root');
        isTimePickerElement =
          innerText !== undefined && innerText.includes('GUARDAR HORA');
        isDamageSelection = className.includes('damageCheck');
      }

      if (
        formikRef.current.dirty &&
        !isEqual(formikRef.current.values, formikRef.current.initialValues) &&
        ((name &&
          !name.includes('custom_') && // Custom fields from custom components are not validated
          String(name).length > 0) ||
          isDatePickerElement ||
          isSelectedElement ||
          isTimePickerElement ||
          isDamageSelection)
      ) {
        // eslint-disable-next-line no-console
        console.log('Procesando cambios...');
        handleSubmit(false);
      }
    }
  };

  const calculateDateDiff = (startDate, endDate): number => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diff = end.diff(start, 'second');

    return diff;
  };

  const onClickForm = (e: any) => {
    setTimeout(() => {
      if (formikRef.current) {
        const { name, className } = e.target;
        let idDamages = false;

        idDamages = String(className)?.includes('damagesModal__saveBtn');

        if (
          formikRef.current.dirty &&
          !isEqual(formikRef.current.values, formikRef.current.initialValues) &&
          ((name && !name.includes('custom_') && String(name).length > 0) ||
            idDamages)
        ) {
          // eslint-disable-next-line no-console
          console.log('Procesando cambios...');
          handleSubmit(false);
        }
      }
    }, 400);
  };

  const handleSubmit = (
    requestNextStep?: boolean | undefined,
    sinister: boolean | undefined = true,
  ) => {
    setIsDisabledButtonConfirm(true);
    const lastDate = new Date();
    const diff = calculateDateDiff(saveInProcess, lastDate);
    if (diff >= 2) {
      setSaveInProcess(lastDate);
      const modifiedFields: any = {};
      const updatedData: any = {};
      setShowConfirmSubmit(false);

      if (formikRef.current) {
        const values = formikRef.current.values;
        Object.keys(values).forEach((fieldName) => {
          if (values[fieldName] !== initialValues[fieldName]) {
            modifiedFields[fieldName] = values[fieldName];
          }
        });

        Object.keys(modifiedFields).forEach((key) => {
          if (key.includes('&')) {
            const updatedKey = key.replace('&', '.');
            set(updatedData, updatedKey, modifiedFields[key]);
          } else {
            set(updatedData, key, modifiedFields[key]);
          }
        });

        if (
          !optionsStep.ChangeStatusRevert &&
          currentClaimForm?.info.countryCode !== CountryCode.COL &&
          sections.stageName === 'DAMAGE_ANALYSIS' &&
          ((currentClaimForm?.info.currentStepStatus ===
            StepStatus.PENDING_ESTIMATE &&
            !state.estimatedAmount &&
            legacyCurrentClaim?.jsonFieldData?.event.CompanyId !==
              BrandsId.CSM) ||
            (currentClaimForm?.info.currentStepStatus ===
              StepStatus.PENDING_ADJUSTMENT &&
              !state.adjustAmount))
        ) {
          setOpenDialogMsg(
            `Para continuar con el proceso, es necesario que realices al menos un ${CurrentStatusText[
              currentClaimForm?.info.currentStepStatus
            ].toLowerCase()}.`,
          );

          setOpenDialog(true);
          setIsDisabledButtonConfirm(false);

          return;
        }

        if (
          currentClaimForm?.info.currentStepStatus ===
          StepStatus.PENDING_APPRO_AGREE
        ) {
          const agreementType = localStorage.getItem('agreementType') ?? '';
          if (
            !optionsStep.ChangeStatusRevert &&
            currentClaimForm?.info.countryCode !== CountryCode.COL &&
            agreementType.length === 0
          ) {
            setOpenDialogMsg(
              'Para continuar con el proceso, es necesario tener los parámetros del acuerdo para el pago / reparación.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          }
        }

        if (
          !optionsStep.ChangeStatusRevert &&
          sections.stageName === 'DAMAGE_ANALYSIS' &&
          currentClaimForm?.info.currentStepStatus ===
            StepStatus.PENDING_ADJUSTMENT &&
          legacyCurrentClaim?.requiresApproval &&
          state.adjustAmount &&
          !legacyCurrentClaim?.closedWithoutPayment
        ) {
          requestApproval();
        }

        if (requestNextStep && optionsStep.ChangeStatusRevert) {
          if (!statusSelected) {
            setOpenDialogMsg(
              'Para continuar con el proceso, seleccione un estado.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          }
        }

        if (
          requestNextStep &&
          (optionsStep.IsClaimNotApplicable || optionsStep.Objeted)
        ) {
          if (!reasonStatusChange || reasonStatusChange?.length < 5) {
            setOpenDialogMsg(
              'Para continuar con el proceso, es necesario agregar una justificación que indique porqué el caso no procede.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          }

          if (optionsStep.Objeted && !statusSelected) {
            setOpenDialogMsg(
              'Para continuar con el proceso, seleccione un estado.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          }
        }

        if (requestNextStep && optionsStep.RequireInformation) {
          if (!textNotification || textNotification?.length < 5) {
            setOpenDialogMsg(
              'Para continuar con el proceso, es necesario agregar un mensaje que indique la información pendiente.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          }

          if (
            (currentClaimForm?.info.countryCode === CountryCode.PAN ||
              currentClaimForm?.info.countryCode === CountryCode.PRI) &&
            !reasonSelect
          ) {
            setOpenDialogMsg(
              'Para continuar con el proceso, es necesario agregar un motivo que indique la información pendiente.',
            );

            setOpenDialog(true);
            setIsDisabledButtonConfirm(false);

            return;
          } else {
            dispatch({ type: ActionType.SET_LOADING, payload: true });
            triggerSave(
              updatedData,
              requestNextStep,
              optionsStep,
              reasonStatusChange,
              currentClaimForm?.info.countryCode === CountryCode.PAN ||
                currentClaimForm?.info.countryCode === CountryCode.PRI
                ? reasonSelect + ' :: ' + textNotification
                : textNotification,
              statusSelected,
              sinister,
            );

            return;
          }
        }

        dispatch({ type: ActionType.SET_LOADING, payload: true });
        triggerSave(
          updatedData,
          requestNextStep,
          optionsStep,
          reasonStatusChange,
          textNotification,
          statusSelected,
          sinister,
        );
      }
    }
  };

  const actionStep = async (
    stepStatus: StepStatus,
    sinister: boolean | undefined,
  ) => {
    switch (stepStatus) {
      case StepStatus.PENDING_SINISTER: {
        if (!sinister) {
          return true;
        }
        const legacy = await getClaimById(claimUId);
        switch (legacy?.jsonFieldData?.event?.CompanyId) {
          case BrandsId.QUALITAS: {
            try {
              await sinisterClaims(legacy?.jsonFieldData, dispatch);

              return true;
            } catch (error) {
              console.warn('Sinister Error:', error);

              return false;
            }
          }
          default:
            return true;
        }
      }
      case StepStatus.PENDING_INSPECT: {
        const legacy = await getClaimById(claimUId);

        if (legacy.jsonFieldData) {
          await finishEvent(legacy?.jsonFieldData);
        }

        return true;
      }
      default:
        return true;
    }
  };

  const requestApproval = async (): Promise<any> => {
    try {
      const result = await new ClaimsTrackerClient().requestApproval(
        claimUId,
        'FINAL_AGREEMENT',
      );
    } catch (e) {
      console.error(e);
    }
  };
  const [visibleDepreciation, setVisibleDepreciation] = useState<boolean>(
    false,
  );

  const targetSection = (
    type: string,
    item: any,
    errors: any,
    touched: any,
    setErrors?: (errors: { [key: string]: any }) => void,
  ) => {
    switch (type) {
      case FieldTypes.PHOTOS:
        return (
          <Photos
            sections={item}
            eventRecord={currentClaimForm?.info?.poNumber}
            claimantType={
              String(currentClaimForm?.info?.type).toUpperCase() as ClaimantType
            }
          />
        );
      case FieldTypes.ESTIMATED_PAYMENT:
        return (
          <>
            <ClaimEstimated
              claimForm={currentClaimForm}
              claimUId={claimUId}
              claimNumber={currentClaimForm?.info?.case}
              poNumber={currentClaimForm?.info?.poNumber}
              claimantType={currentClaimForm?.info?.type}
              disabled={isDisabled}
              accountReference={
                Number(legacyCurrentClaim?.accountReference) || 0
              }
            />
          </>
        );
      case FieldTypes.EXPENSES:
        return (
          <ClaimExpenses
            claimNumber={currentClaimForm?.info?.case}
            poNumber={currentClaimForm?.info?.poNumber}
            claimUId={claimUId}
            claimantType={currentClaimForm?.info?.type}
            disabled={isDisabled}
          />
        );
      case FieldTypes.REQUEST_CLAIMS:
        return (
          <ClaimRequest
            currentClaim={legacyCurrentClaim}
            onLoad={(value: boolean) => {
              dispatch({ type: ActionType.SET_LOADING, payload: value });
            }}
            disabled={isDisabled}
            onRefresh={() => {
              (async (): Promise<void> => {
                try {
                  const searchClaim = await getClaimById(claimUId);
                  if (setLegacyCurrentClaim) {
                    setLegacyCurrentClaim(searchClaim);
                  }
                  dispatch({ type: ActionType.SET_LOADING, payload: false });
                } catch (e) {
                  dispatch({ type: ActionType.SET_LOADING, payload: false });
                }
              })();
            }}
            onNotify={(message: string, isWarn = false) => {
              dispatch({
                type: ActionType.SET_GENERAL_ALERT,
                payload: {
                  showAlert: true,
                  alertMessage: message,
                  alertType: isWarn ? 'warning' : 'success',
                },
              });
            }}
          />
        );
      case FieldTypes.AGREEMENT_DETAIL:
        return (
          <AgreementDetail
            claimUId={claimUId}
            claimForm={currentClaimForm}
            disabled={isDisabled}
            legacyCurrentClaim={legacyCurrentClaim}
            disableFinishActionState={setIsFinishDisableByCondition}
          />
        );
      case FieldTypes.TOTAL_LOSS:
        return (
          <TotalLoss
            claimUId={claimUId}
            claimForm={currentClaimForm}
            disabled={isDisabled}
            visibleDepreciation={setVisibleDepreciation}
          />
        );
      case FieldTypes.SUBROGATION:
        return (
          <Subrogation
            claimUId={claimUId}
            claimForm={currentClaimForm}
            notifySubrogation={legacyCurrentClaim?.notifySubrogation}
            disabled={isDisabled}
          />
        );
      case FieldTypes.EVENT_TRACKING:
        return (
          <EventTracking claimUId={claimUId} claimForm={currentClaimForm} />
        );
      case FieldTypes.FINAL_RESOLUTION:
        return (
          <FinalResolution
            claimUId={claimUId}
            claimForm={currentClaimForm}
            notifySubrogation={legacyCurrentClaim?.notifySubrogation}
            disabled={isDisabled}
          />
        );
      case FieldTypes.DEPRECIATION:
        return (
          <>
            {visibleDepreciation ? (
              <Depreciation
                claimUId={claimUId}
                claimNumber={currentClaimForm?.info?.case}
                poNumber={currentClaimForm?.info?.poNumber}
                totalIsured={currentClaimForm?.info?.policy.totalInsured}
                claimantType={currentClaimForm?.info?.type}
              />
            ) : null}
          </>
        );
      case FieldTypes.THIRDS_CLAIMS:
        return (
          <ThirdsForm
            claim={currentClaimForm}
            isInspectorForm={false}
            type={FieldTypes.THIRDS_CLAIMS}
          />
        );
      case FieldTypes.INJURED_CLAIMS:
        return (
          <ThirdsForm
            claim={currentClaimForm}
            isInspectorForm={false}
            type={FieldTypes.INJURED_CLAIMS}
          />
        );
      case FieldTypes.AUTO_PARTS:
        return <ClaimAutoParts claimUId={claimUId} disabled={false} />;
      case FieldTypes.CIRCUNSTANCES_CLAIMS:
        return (
          <CircunstancesFormClaims
            circunstancesList={CATALOGS.circunstances}
            thirdPlate={
              legacyCurrentClaim?.jsonFieldData?.event.plateCircunstancesThird
            }
            thirdInsurance={
              legacyCurrentClaim?.jsonFieldData?.event
                .insuranceCircunstancesThird
            }
            isActive={Number(localStorage.getItem('rolId')) !== Rol.INSURER}
            codeVehicleA={
              legacyCurrentClaim?.jsonFieldData?.event.insuredCodeConsequences
            }
            codeVehicleB={
              legacyCurrentClaim?.jsonFieldData?.event.thirdCodeConsequences
            }
            responsable={
              legacyCurrentClaim?.jsonFieldData?.event.responsableConsequences
            }
            save={(data: any) => {
              triggerSave(data, false);
            }}
          />
        );
      default: {
        return (
          <FormFieldsComponent
            section={item}
            claim={currentClaimForm?.info}
            event={legacyCurrentClaim?.jsonFieldData}
            errors={errors}
            touched={touched}
            disabled={isDisabled}
            setErrors={setErrors}
          />
        );
      }
    }
  };

  const handleGeneratePDF = (): void => {
    setLoading(true);
    (async (): Promise<void> => {
      await API.postGeneratePDFs(currentClaimForm?.info.poNumber);
      setLoading(false);
    })();
  };

  const download = async () => {
    // La descarga del evento se hará depenindo de si es un estado mayor a 2
    if (legacyCurrentClaim?.eventRecord) {
      downloadEvent(
        currentClaimForm?.info.poNumber,
        currentClaimForm?.info.countryCode,
        Number(localStorage.getItem('rolId')),
        dispatch,
      );
    }
  };

  const sendPdf = async () => {
    const eventNumber = legacyCurrentClaim?.eventRecord ?? '';
    const response = await sendClientPdf(eventNumber);

    const message =
      response === ''
        ? 'El Pdf fue enviado con éxito.'
        : 'Ocurrió un error al enviar el Pdf.';
    dispatch({
      type: ActionType.SET_GENERAL_ALERT,
      payload: {
        showAlert: true,
        alertMessage: message,
        alertType: response === '' ? 'success' : 'error',
      },
    });
  };

  const triggerActiveLink = async () => {
    try {
      setLoading(true);
      const response = await sendActiveLink(legacyCurrentClaim?.eventId);
      setLoading(false);
      const message = response
        ? '<b>Solicitud enviada satisfactoriamente. Ver más detalles en la bitácora del evento.</b>'
        : '<b>No se puede enviar la solicitud. Reintente nuevamente.</b>';
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage: message,
          alertType: response ? 'success' : 'error',
        },
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }

    setConfirmActiveLink(false);
  };

  const checkFieldDependencySection = (dependencyJson: any): boolean => {
    const dependencyFields = JSON.parse(dependencyJson);

    let _showSection = true;

    if (dependencyFields) {
      for (const dependency of dependencyFields) {
        const _containers = steps
          .map((item) =>
            item.sections.find(
              (section) => section.id === dependency.SectionId,
            ),
          )
          .find((section) => section !== undefined)?.containers;

        const _section = _containers ? _containers[0] : null;

        //validacion de dependencias de campos que estan dentro de componentes custom
        if (!_section) {
          switch (dependency.Name) {
            case 'thirdType': {
              const value = state?.currentInspection?.thirds
                ? (state?.currentInspection?.thirds[0] as any)?.thirdType
                : undefined;

              if (value && value !== dependency.ExpectedValue) {
                return false;
              }
            }
          }
        }

        if (_section) {
          const _field = _section.fields?.find(
            (f: any) => f.keyName === dependency.Name,
          );

          const values = JSON.parse(
            sessionStorage.getItem('formikValues') || '{}',
          );

          if (_field && values) {
            const value =
              values[_field.modelBinding] !== undefined
                ? values[_field.modelBinding]
                : _field.defaultValue;

            if (value && value !== dependency.ExpectedValue) {
              _showSection = false;
              break;
            }
          }
        }
      }
    }

    return _showSection;
  };

  const detectStatusForFinalizeButton = (): void => {
    let detectStatus = isDisabledButton; // Detect is disable by some conditions (see isDisabledButton variable)

    detectStatus = !detectStatus ? !enabledByStates : detectStatus; // Detect is disable by the Current Status
    detectStatus = !detectStatus ? isFinishDisableByCondition : detectStatus; // Detect is disable by condition
    detectStatus = !detectStatus ? pendingApproval : detectStatus; // Detect is disable by approval
    detectStatus = !detectStatus
      ? sections.stageName !== currentClaimForm?.info?.currentStep
      : detectStatus; // Detect current step is distinct selected step

    setFinishBtnIsDisable(detectStatus);
  };

  const validateStatusByAttributes = (): void => {
    if (
      legacyCurrentClaim &&
      legacyCurrentClaim?.jsonFieldData &&
      currentClaimForm
    ) {
      const { event } = legacyCurrentClaim?.jsonFieldData;
      const { info } = currentClaimForm;

      if (
        BrandsId.CSM === (event.CompanyId ?? 0) &&
        event.IndEstimate === '2' &&
        info?.currentStep === 'INSPECT_SECTION' &&
        info?.currentStepStatus === 'PENDING_AUDIT'
      ) {
        setShowFinishWithoutEstimatedButton(
          true &&
            (userRole === Rol.ADJUSTER ||
              userRole === Rol.ADMIN ||
              userRole === Rol.AUDIT),
        );
      }

      if (
        BrandsId.CSM === (event.CompanyId ?? 0) &&
        event.IndEstimate === '1' &&
        info?.currentStep === 'INSPECT_SECTION' &&
        info?.currentStepStatus === 'PENDING_AUDIT'
      ) {
        setShowFinishWithoutEstimatedButton(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      detectStatusForFinalizeButton();
      setIsFirstRender(false);
    }, 2500);
  }, [state.claimsManagementInfo]);

  useEffect(() => {
    if (!isFirstRender) {
      setIsFinishDisableByCondition(false);
      // Detect status
      detectStatusForFinalizeButton();
    }
  }, [sections]);

  useEffect(() => {
    validateStatusByAttributes();
  }, [legacyCurrentClaim]);

  return (
    <div className={isDetectedChanges ? 'fix-height-detail' : ''}>
      <Loader isActive={loading} />
      {isDetectedError && (
        <>
          <br />
          <Stack sx={{ width: '100%' }} spacing={2} className="alertError">
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsDetectedError(false);
                  }}
                  sx={{ mr: 1 }}
                >
                  X
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>
                <strong>¡Hemos tenido un problema!</strong>
              </AlertTitle>
              <p>
                No hemos podido procesar la acción solicitada. Por favor intente
                nuevamente, si el problema persiste comunicarse con soporte
                técnico y compartir la información del detalle.
              </p>
              <Accordion sx={{ backgroundColor: '#FDEDED' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Detalle</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <code>{msgError}</code>
                </AccordionDetails>
              </Accordion>
            </Alert>
          </Stack>
        </>
      )}

      {sections && (
        <Box className="claim-sections">
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={() => {
              console.warn('On submit');
            }}
            validateOnMount={true}
          >
            {({ errors, touched, isValid, setErrors }) => {
              useEffect(() => {
                sections.isInvalid = !isValid;
              }, [isValid]);

              return (
                <Form onBlur={onChangeForm} onClick={onClickForm}>
                  <Box>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <p className="claim-sections__header--title">
                          {sections.title}
                        </p>
                        <p className="claim-sections__header--subtitle">
                          Fecha de {sections.title}:{' '}
                          {formatDate(
                            sections.title === 'Subrogación'
                              ? currentClaimForm?.info?.subrogation
                                  .SubrogationCreateDate
                              : sections.initialDate,
                          )}
                        </p>
                        {sections.title === 'Subrogación' && (
                          <Grid container>
                            <Grid item>
                              <p
                                style={{
                                  color: '#F15B2B',
                                  backgroundColor: '#FFF2EE',
                                  padding: 8,
                                  margin: 10,
                                }}
                              >
                                ASEGURADORA:{' '}
                                {currentClaimForm?.info?.subrogation.insurance}
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                style={{
                                  color: '#001D3D',
                                  backgroundColor: '#EEF7FF',
                                  padding: 8,
                                  margin: 10,
                                }}
                              >
                                AJUSTADOR:{' '}
                                {currentClaimForm?.info?.subrogation.user}
                              </p>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {userRole !==
                        roles.roles.filter(
                          (x) => x.name === 'auxiliar de reclamaciones',
                        )[0].id && (
                        <Grid item xs={12} sm={12} md={6}>
                          <Box sx={{ float: 'right' }}>
                            {showButtonChangeStatus && (
                              <>
                                <Button
                                  id="ChangeStatusRevert"
                                  text={'Cambio Estado'}
                                  onClick={handleClickOptionStep}
                                  style={customStyle}
                                />
                                |
                              </>
                            )}

                            {showFinishWithoutSinister && (
                              <>
                                <Button
                                  text={'Finalizar sin Siniestrar'}
                                  onClick={() => {
                                    handleSubmit(true, false);
                                  }}
                                  style={customStyle}
                                  variant="outlined"
                                  className="claim-button__outline-orange"
                                />
                                |
                              </>
                            )}

                            {showFinishWithoutEstimatedButton && (
                              <>
                                <Button
                                  id="IsFinishedWithoutEstimated"
                                  text={'Finalizar auditoría y finalizar'}
                                  onClick={handleClickOptionStep}
                                  style={customStyle}
                                  variant="outlined"
                                  className="claim-button__outline-orange"
                                />
                                |
                              </>
                            )}

                            {[
                              CountryCode.PAN,
                              CountryCode.CRC,
                              CountryCode.PRI,
                            ].includes(currentClaimForm?.info.countryCode) && (
                              <>
                                <Button
                                  key="btnDownloadButton"
                                  color="secondary"
                                  text="Descargar"
                                  onClick={() => download()}
                                ></Button>
                                |
                              </>
                            )}

                            {[CountryCode.CRC].includes(
                              currentClaimForm?.info.countryCode,
                            ) &&
                              ![Rol.CUSTOMER].includes(
                                Number(localStorage.getItem('rolId')),
                              ) && (
                                <>
                                  <Button
                                    key="btnPdfbutton"
                                    color="secondary"
                                    text="Enviar PDF"
                                    onClick={() => sendPdf()}
                                  ></Button>
                                  |
                                </>
                              )}

                            {(currentClaimForm?.info.countryCode ===
                              CountryCode.PAN ||
                              currentClaimForm?.info.countryCode ===
                                CountryCode.PRI) && (
                              <>
                                <Button
                                  key="btnActiveInspection"
                                  color="secondary"
                                  text="Habilitar Enlace"
                                  onClick={() => {
                                    if (
                                      currentClaimForm?.info.countryCode ===
                                      CountryCode.PAN
                                    ) {
                                      triggerActiveLink();
                                    } else {
                                      setConfirmActiveLink(true);
                                    }
                                  }}
                                ></Button>
                                |
                              </>
                            )}
                            {currentClaimForm?.info?.sicStatusId >=
                              TransitionStatesIds.COMPLETED &&
                              ![Rol.CUSTOMER].includes(
                                Number(localStorage.getItem('rolId')),
                              ) && (
                                <>
                                  <Button
                                    text="Generar PDFs"
                                    color="secondary"
                                    onClick={handleGeneratePDF}
                                  />
                                  |
                                </>
                              )}

                            <Button
                              disabled={
                                isDisabledButton ||
                                !isValid ||
                                !enabledByStates ||
                                isFinishDisableByCondition ||
                                pendingApproval
                              }
                              text={
                                sections.submitTitle
                                  ? `Finalizar ${sections.submitTitle}`
                                  : 'Finalizado'
                              }
                              onClick={() => {
                                initOptionStep();
                                setReasonStatusChange('');
                                setTextNotification('');
                                setStatusSelected(undefined);
                                setIsDisabledButtonConfirm(false);
                                setShowConfirmSubmit(true);
                              }}
                              style={customStyle}
                            />

                            {showButtonPendingInfo && (
                              <>
                                |
                                <Button
                                  id="RequireInformation"
                                  text={'Pendiente Información'}
                                  onClick={handleClickOptionStep}
                                  style={customStyle}
                                />
                              </>
                            )}

                            {showButtonObjeted && (
                              <>
                                |
                                <Button
                                  id="Objeted"
                                  text={'Objetado'}
                                  onClick={handleClickOptionStep}
                                  style={customStyle}
                                />
                              </>
                            )}

                            {showButtonNotApplicable && (
                              <>
                                |
                                <Button
                                  id="IsClaimNotApplicable"
                                  text={'No procede'}
                                  onClick={handleClickOptionStep}
                                  style={customStyle}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Box className="claim-sections__content-accordion">
                    {sections.sections
                      .map((item: any) => {
                        return { ...item };
                      })
                      .map((item: any) => {
                        const {
                          id,
                          title,
                          constraints,
                          containers,
                          dependencyJson,
                        } = item;

                        const showSection = checkFieldDependencySection(
                          dependencyJson,
                        );

                        const hasError = containers.some((container: any) =>
                          container.fields.some((field: any) => {
                            const key = field.modelBinding;
                            if (!key) {
                              return false;
                            }

                            const validationKey = key.includes('.')
                              ? key.replace(/\./g, '&')
                              : key;

                            const isError = Object.prototype.hasOwnProperty.call(
                              errors,
                              validationKey,
                            );

                            if (!showSection && isError) {
                              const {
                                [validationKey]: _,
                                ...updatedErrors
                              } = errors;

                              setErrors(updatedErrors);
                            }

                            return isError;
                          }),
                        );

                        return (
                          constraints.isVisible &&
                          showSection &&
                          (item.targetSection !== FieldTypes.DEPRECIATION ? (
                            <Accordion
                              key={id}
                              className="accordion"
                              expanded={expanded[id] || hasError || false}
                              onChange={handleChange(id)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${id}-content`}
                                id={`panel${id}-header`}
                              >
                                <Typography
                                  className={`typography ${
                                    hasError ? 'hasError' : ''
                                  }`}
                                >
                                  {title}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {targetSection(
                                  item.targetSection,
                                  item,
                                  errors,
                                  touched,
                                  setErrors,
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ) : null)
                        );
                      })}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}

      <Dialog open={showConfirmSubmit} style={{ fontFamily: 'Connect' }}>
        <DialogTitle
          id="confirm-restore-title"
          style={{ color: '#001d3d', fontFamily: 'Connect' }}
        >
          Confirmar Paso
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-step-modal"
            style={{ width: '450px', minHeight: '120px' }}
          >
            <br />
            <a className="modal-confirm-title">
              ¿Está seguro que desea aplicar la finalización de este paso?
            </a>
            <br />
            {(optionsStep.IsClaimNotApplicable || optionsStep.Objeted) && (
              <>
                <br />
                <TextField
                  className="modal-confirm-input"
                  id="input-not-applicable"
                  label="Ingrese los detalles del evento estado no procede"
                  type="text"
                  required
                  multiline
                  fullWidth
                  value={reasonStatusChange}
                  onChange={(event: any) =>
                    setReasonStatusChange(event.target?.value)
                  }
                ></TextField>
              </>
            )}
            {(optionsStep.Objeted || optionsStep.ChangeStatusRevert) && (
              <div style={{ marginTop: '10px' }}>
                <FormControl fullWidth>
                  <InputLabel id="label-status">Estado *</InputLabel>
                  <Select
                    labelId="label-status"
                    id="demo-simple-select"
                    label="Estado *"
                    onChange={handleChangeStatus}
                  >
                    {listStatus[currentClaimForm?.info?.countryCode]?.map(
                      (status, index) => {
                        return (
                          Number(
                            StatusIds[
                              currentClaimForm?.info?.currentStepStatus
                            ],
                          ) > status.newStatus.StatusId && (
                            <MenuItem
                              key={index}
                              value={`${status.newStatus.StageId}-${status.newStatus.StatusId}`}
                            >
                              {status.text}
                            </MenuItem>
                          )
                        );
                      },
                    )}
                  </Select>
                </FormControl>
              </div>
            )}
            <br></br>
            {optionsStep.RequireInformation && (
              <>
                {(currentClaimForm?.info.countryCode === CountryCode.PAN ||
                  currentClaimForm?.info.countryCode === CountryCode.PRI) && (
                  <>
                    <div className="reasonContainer">
                      <div className="reasonLabel">Motivo: </div>
                      <div>
                        <select
                          name="reasonSelect"
                          id="reasonSelect"
                          className="reasonSelect"
                          value={reasonSelect}
                          onChange={(event: any) => {
                            setReasonSelect(event.target.value);
                          }}
                        >
                          <option value="">Seleccione un motivo</option>
                          {reasonPendingInfo?.map((reason) => (
                            <option key={reason.id} value={reason.reason}>
                              {reason.reason}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                <TextField
                  className="modal-confirm-input"
                  id="input-pending-info"
                  label="Ingrese el mensaje de la información pendiente"
                  type="text"
                  required
                  multiline
                  fullWidth
                  value={textNotification}
                  onChange={(event: any) =>
                    setTextNotification(event.target?.value)
                  }
                ></TextField>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setShowConfirmSubmit(false);
              initOptionStep();
              setReasonStatusChange('');
              setStatusSelected(undefined);
              setTextNotification('');
            }}
          />
          <Button
            disabled={isDisabledButtonConfirm}
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit(true);
            }}
          />
        </DialogActions>
      </Dialog>

      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={openDialogMsg}
        open={openDialog}
        handleClose={handleCloseDialog}
      />

      {isDetectedChanges && (
        <Stack sx={{ width: '100%' }} spacing={2} className="alertSavedChanges">
          <Alert severity="info">
            <AlertTitle>
              <strong>Hemos detectado cambios</strong>
            </AlertTitle>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <p>¿Deseas guardar los cambios realizados en este paso?</p>
              </Grid>
              <Grid item md={4} sx={{ textAlign: 'right' }}>
                <Button
                  text="Guardar cambios"
                  type="buttom"
                  style={customStyle}
                  onClick={() => handleSubmit(false)}
                />
              </Grid>
            </Grid>
          </Alert>
        </Stack>
      )}
      <Dialog
        open={confirmActiveLink}
        onClose={() => setConfirmActiveLink(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Acceso a Formulario de Inspección
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea activar el enlace externo para acceder al formulario de
            inspección?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setConfirmActiveLink(false)}
            color="secondary"
            text="Cancelar"
          ></Button>
          <Button
            variant="contained"
            onClick={triggerActiveLink}
            color="primary"
            text="Confirmar"
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClaimSections;
